import React from "react"
import { useSwipeable } from "react-swipeable"

import PropTypes from "prop-types"

import { CartContext } from "../../store"
import { parseContentJson } from "../../utils"
import Button from "../common/Button"
import Input from "../common/Input"
import Link from "../common/Link"
import Select from "../common/Select"

import "./ProductDescription.scss"

const ProductDescription = ({ product }) => {
  const description = parseContentJson(product.descriptionJson)

  const { addItem } = React.useContext(CartContext)

  const hasVariants = !!product.variants[0]

  const [selectedImage, setSelectedImage] = React.useState(0)
  const [quantity, setQuantity] = React.useState(1)
  const [selectedVariant, setSelectedVariant] = React.useState(
    product.variants[0]?.id
  )
  const [selectedVariantPrice, setSelectedVariantPrice] = React.useState(
    product.variants[0]?.pricing.price.gross.amount
  )
  const [initialPrice, setInitialPrice] = React.useState(
    product.variants[0]?.pricing.priceUndiscounted.gross.amount
  )
  const [selectedVariantTitle, setSelectedVariantTitle] = React.useState(
    product.variants[0]?.name
  )
  const [isOnSale, setIsOnSale] = React.useState(
    product.variants[0]?.pricing.onSale
  )

  const [currentListOfImages, setCurrentListOfImages] = React.useState([])
  const [commonImagesList, setCommonImagesList] = React.useState([])

  const variantOptions = [
    {
      label: "Select Variant",
      value: "",
    },
    ...product.variants.map((variant) => ({
      label: variant.name || "Default",
      value: variant.id,
    })),
  ]

  React.useEffect(() => {
    const variantImgArr = []
    const tempCommonList = []
    product.variants.forEach((variant) => {
      variant.images.forEach((img) => {
        variantImgArr.push(img)
      })
    })
    for (const prodImg of product.images) {
      let flag = 0
      for (const varImg of variantImgArr) {
        if (varImg.url === prodImg.url) {
          flag = 1
          break
        }
      }
      if (flag === 0) {
        tempCommonList.push(prodImg)
      }
    }
    setCommonImagesList(tempCommonList)
    if (product.defaultVariant?.images) {
      setCurrentListOfImages([
        ...product.defaultVariant.images,
        ...tempCommonList,
      ])
    } else {
      setCurrentListOfImages(tempCommonList)
    }
  }, [product])

  React.useEffect(() => {
    if (product.variants) {
      const selectedVariantObject = product.variants.filter(
        (variant) => variant.id === selectedVariant
      )[0]
      setSelectedVariantPrice(selectedVariantObject.pricing.price.gross.amount)
      setInitialPrice(
        selectedVariantObject.pricing.priceUndiscounted.gross.amount
      )
      setSelectedVariantTitle(selectedVariantObject.name)
      setIsOnSale(selectedVariantObject.pricing.onSale)
    }
  }, [selectedVariant, product.variants])

  const addToCart = (numberOfItems) => {
    addItem(
      selectedVariant,
      parseInt(numberOfItems),
      product.name,
      selectedVariantPrice,
      selectedVariantTitle
    )
  }
  const handleClick = () => {
    if (!quantity) {
      setQuantity(1)
      addToCart(1)
      return
    }
    addToCart(quantity)
  }

  const handleSelect = (e) => {
    if (selectedVariant === e.target.value) return
    const current = product.variants.find((el) => el.id === e.target.value)
    if (current) {
      setCurrentListOfImages([...current.images, ...commonImagesList])
      setSelectedVariant(e.target.value)
      setSelectedImage(0)
    }
  }

  const handleChange = (e) => {
    const value =
      e.target.value !== "" && e.target.value < 1 ? 1 : e.target.value
    setQuantity(value)
  }

  const handleKeydown = (e) => {
    if (
      e.code === "Minus" ||
      e.code === "KeyE" ||
      e.code === "Period" ||
      e.key === "+"
    ) {
      e.preventDefault()
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      selectedImage < product.images.length - 1
        ? setSelectedImage(selectedImage + 1)
        : setSelectedImage(0),
    onSwipedRight: () =>
      selectedImage > 0
        ? setSelectedImage(selectedImage - 1)
        : setSelectedImage(product.images.length - 1),
  })

  return (
    <section className="product-description">
      <div className="product-description__container">
        <div className="product-description__image">
          <div className="product-description__image__slider">
            {currentListOfImages.map((image, index) => (
              <div
                key={index}
                onClick={() => setSelectedImage(index)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") setSelectedImage(index)
                }}
                className={`has-cursor-pointer image-wrapper ${
                  selectedImage === index && "image-wrapper--selected"
                } `}
                role="button"
                tabIndex={0}
              >
                <img src={image.url} alt={image.alt} loading="lazy" />
              </div>
            ))}
          </div>
          <figure className="product-description__image__main" {...handlers}>
            {currentListOfImages[selectedImage]?.url ? (
              <img
                src={currentListOfImages[selectedImage]?.url}
                alt={currentListOfImages[selectedImage]?.alt}
                loading="lazy"
              />
            ) : (
              <span>No Image</span>
            )}
          </figure>
        </div>
        <div className="product-description__details">
          <div className="product-description__details__title">
            {product.name}
          </div>
          <Link
            to={`/products/type/${product.productType.slug}`}
            className="product-description__details__type"
          >
            {product.productType.name}
          </Link>{" "}
          {hasVariants ? (
            <>
              <div className="product-description__details__variants">
                <Select
                  options={variantOptions}
                  onChange={handleSelect}
                  value={selectedVariant}
                />
              </div>
              <div className="product-description__details__quantity">
                <Input
                  min="1"
                  type="number"
                  id="quantity"
                  value={quantity}
                  placeholder="Quantity"
                  onChange={handleChange}
                  onKeyDown={handleKeydown}
                />
              </div>
              <div className="product-description__details__pricing-container">
                {isOnSale && (
                  <div className="product-description__details__pricing product-description__details__pricing--undiscounted">
                    ₹ {initialPrice}
                  </div>
                )}
                <div className="product-description__details__pricing">
                  ₹ {selectedVariantPrice}
                </div>
              </div>
              <div className="product-description__details__cart">
                <Button color="primary" onClick={handleClick} isLarge>
                  Add to Cart
                </Button>
              </div>
            </>
          ) : (
            <div className="product-description__unavailable">
              Not Available
            </div>
          )}
        </div>
      </div>
      <article
        className="product-description__body"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="product-description__metadata">
        {product.metadata.map((data, i) => (
          <div className="product-description__metadata__item" key={i}>
            <p>
              <strong>{data.key}</strong>
            </p>
            <p>{data.value}</p>
          </div>
        ))}
      </div>
    </section>
  )
}

ProductDescription.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductDescription
