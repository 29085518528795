import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductDescription from "../components/products/ProductDescription"
import ProductList from "../components/products/ProductList"
import SEO from "../components/seo"

const ProductTemplate = ({ data }) => {
  const { product } = data.saleor
  const [similarProdcts, setSimilarProducts] = React.useState({})

  React.useEffect(() => {
    if (product) {
      const similar = product.productType.products.edges.filter(
        (prod) => prod.node.id !== product.id
      )
      setSimilarProducts({
        edges: similar,
      })
    }
  }, [product])

  return (
    <>
      <SEO title={product.seoTitle} path={product.seoDescription} />
      <ProductDescription product={product} />
      <ProductList
        title="Similar Products"
        slug={product.slug}
        products={similarProdcts}
      />
    </>
  )
}

export const productQuery = graphql`
  query ProductBySlug($slug: String!) {
    saleor {
      product(slug: $slug) {
        id
        slug
        name
        seoTitle
        seoDescription
        descriptionJson
        defaultVariant {
          name
          id
          images {
            url
            alt
          }
          pricing {
            onSale
            price {
              gross {
                amount
                currency
              }
            }
            priceUndiscounted {
              gross {
                amount
                currency
              }
            }
          }
        }
        visibleInListings
        isAvailable
        isAvailableForPurchase
        pricing {
          discount {
            gross {
              amount
              currency
            }
            net {
              amount
              currency
            }
            tax {
              amount
              currency
            }
          }
          priceRange {
            start {
              gross {
                amount
                currency
              }
              net {
                amount
                currency
              }
              tax {
                amount
                currency
              }
            }
            stop {
              gross {
                amount
                currency
              }
              net {
                amount
                currency
              }
              tax {
                amount
                currency
              }
            }
          }
          priceRangeUndiscounted {
            start {
              gross {
                amount
                currency
              }
              net {
                amount
                currency
              }
              tax {
                amount
                currency
              }
            }
            stop {
              gross {
                amount
                currency
              }
              net {
                amount
                currency
              }
              tax {
                amount
                currency
              }
            }
          }
        }
        metadata {
          key
          value
        }
        category {
          name
        }
        variants {
          name
          id
          images {
            url
            alt
          }
          pricing {
            onSale
            price {
              gross {
                amount
                currency
              }
            }
            priceUndiscounted {
              gross {
                amount
                currency
              }
            }
          }
        }
        productType {
          name
          slug
          products(first: 5) {
            edges {
              node {
                id
                slug
                name
                visibleInListings
                isAvailable
                isAvailableForPurchase
                variants {
                  name
                  id
                  pricing {
                    price {
                      gross {
                        amount
                        currency
                      }
                    }
                  }
                }
                thumbnail {
                  url
                  alt
                }
                pricing {
                  onSale
                  priceRange {
                    start {
                      net {
                        amount
                      }
                    }
                    stop {
                      net {
                        amount
                      }
                    }
                  }
                }
              }
            }
          }
        }
        pricing {
          onSale
          priceRange {
            start {
              net {
                amount
              }
            }
            stop {
              net {
                amount
              }
            }
          }
        }
        thumbnail {
          url
          alt
        }
        images {
          url
          alt
        }
      }
    }
  }
`

ProductTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductTemplate
